
const CheckoutEmailUpdatePayload = {
  "name": "CheckoutEmailUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "checkoutUserErrors": "CheckoutUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutEmailUpdatePayload.fieldBaseTypes);
export default Object.freeze(CheckoutEmailUpdatePayload);