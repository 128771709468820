import "./styles/main.scss";
import ReactDOM from "react-dom";
import { useEffect, useState, useCallback } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import ReactGA from "react-ga4";
import Home from "./components/Home";
import Spinner from "./components/Spinner/Spinner";
import PageNotFound from "./components/PageNotFound/PageNotFound";

import * as API from "./api/shop";

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;
const FOMO_ENV = process.env.FOMO_ENV;

const App = () => {
  //Internal usage
  const [is404, setIs404] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [microsite, setMicrosite] = useState();
  const [products, setProducts] = useState();
  const [checkout, setCheckout] = useState();
  const [cartQty, setCartQty] = useState(0);
  const [slug, setSlug] = useState();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const updateResults_ = ({ totalItems, checkout }) => {
    setCartQty(totalItems);
    setCheckout(checkout);
  };

  const addLineItemToCart = async (variantId, quantity) => {
    const lineItems = [{ variantId, quantity: parseInt(quantity, 10) }];
    ReactGA.event("add_to_cart", { variantId, quantity });
    const result = await API.addLineItems(
      microsite.store.storefrontAccessToken,
      microsite.store.shop,
      checkout.id,
      lineItems
    );
    updateResults_(result);
  };

  const updateQuantityInCart = async (lineItemId, quantity) => {
    const lineItems = [{ id: lineItemId, quantity: parseInt(quantity, 10) }];
    ReactGA.event("update_quantity_cart", { lineItemId, quantity });
    const result = await API.updateLineItems(
      microsite.store.storefrontAccessToken,
      microsite.store.shop,
      checkout.id,
      lineItems
    );
    updateResults_(result);
  };

  const removeLineItemInCart = async (lineItemId) => {
    ReactGA.event("remove_from_cart", { lineItemId });
    const result = await API.removeLineItems(
      microsite.store.storefrontAccessToken,
      microsite.store.shop,
      checkout.id,
      [lineItemId]
    );
    updateResults_(result);
  };

  const openCheckout = useCallback(async () => {
    ReactGA.event("start_checkout");
    if (!executeRecaptcha) {
      console.error(
        "Execute recaptcha not available yet. Checkout not possible!"
      );
      return;
    }
    const variantIds = checkout.attrs.lineItems.map((lineItem) => {
      let ret;
      try {
        ret = window.atob(lineItem.attrs.variant.attrs.id.value);
      } catch {
        ret = lineItem.attrs.variant.attrs.id.value;
      }
      return ret;
    });
    try {
      const recaptchaToken = await executeRecaptcha("checkout");
      const code = await API.fetchDiscountCode(
        slug,
        variantIds,
        recaptchaToken
      );
      await API.applyDiscountCode(
        microsite.store.storefrontAccessToken,
        microsite.store.shop,
        checkout.id,
        code
      );

      //Redirect to checkout url
      window.location.assign(checkout.webUrl);
    } catch (e) {
      console.error(e);
    }
  }, [executeRecaptcha, checkout, microsite]);

  const completeLoading = () => setLoading(false);

  useEffect(() => {
    //retrieve microsite configuration + products
    //create checkout object
    //loading done
    (async () => {
      const url = window.location.toString();
      //const slug = url[url.length - 1];
      const slug = parseSlug(url);
      if (!slug) {
        setIs404(true);
        return;
      }
      setSlug(slug);

      // Fetch microsite from backend
      const micrositeResult = await API.fetchMicrosite(slug);
      if (micrositeResult === null) {
        setIs404(true);
        return;
      }
      const {
        brandId,
        campaignId,
        microsite: site,
        products,
      } = micrositeResult;
      const devEnvs = ["dev", "staging", "playground"];
      const measurementId = devEnvs.includes(FOMO_ENV)
        ? "G-XWVMM7ZRE6"
        : "G-BHDG080W2L";
      ReactGA.initialize(measurementId, {
        gtagOptions: {
          campaignId,
          brandId,
        },
      });
      ReactGA.send({ hitType: "pageview", page: slug });
      setMicrosite(site);
      setProducts(products);
      document.title = `${site.settingsGeneral.titleBrand} x ${site.settingsGeneral.influencer}`;
      setCheckout(
        await API.createCheckout(
          site.store.storefrontAccessToken,
          site.store.shop
        )
      );
      completeLoading();
    })();
  }, [setMicrosite, setProducts, setLoading, setSlug]);

  if (is404) {
    return <PageNotFound />;
  }

  if (isLoading) {
    return <Spinner isLoading={isLoading} />;
  }

  return (
    <>
      <Home
        checkout={checkout}
        microsite={microsite}
        products={products}
        cartQty={cartQty}
        addLineItemToCart={addLineItemToCart}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
        openCheckout={openCheckout}
      />
    </>
  );
};

const parseSlug = (url) => {
  return url.split("#")[0].split("?")[0].split("/").slice(-3).join("/");
};

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
