
const CheckoutGiftCardsAppendPayload = {
  "name": "CheckoutGiftCardsAppendPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "checkoutUserErrors": "CheckoutUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutGiftCardsAppendPayload.fieldBaseTypes);
export default Object.freeze(CheckoutGiftCardsAppendPayload);