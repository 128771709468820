
const CreditCardPaymentInputV2 = {
  "name": "CreditCardPaymentInputV2",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "paymentAmount": "MoneyInput",
    "idempotencyKey": "String",
    "billingAddress": "MailingAddressInput",
    "vaultId": "String",
    "test": "Boolean"
  }
};
Object.freeze(CreditCardPaymentInputV2.inputFieldBaseTypes);
export default Object.freeze(CreditCardPaymentInputV2);