
const SEO = {
  "name": "SEO",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "title": "String"
  },
  "implementsNode": false
};
Object.freeze(SEO.fieldBaseTypes);
export default Object.freeze(SEO);