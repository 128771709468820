
const FulfillmentLineItemConnection = {
  "name": "FulfillmentLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "FulfillmentLineItemEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(FulfillmentLineItemConnection.fieldBaseTypes);
export default Object.freeze(FulfillmentLineItemConnection);