
const CheckoutCustomerAssociatePayload = {
  "name": "CheckoutCustomerAssociatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "customer": "Customer",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCustomerAssociatePayload.fieldBaseTypes);
export default Object.freeze(CheckoutCustomerAssociatePayload);