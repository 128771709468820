
const Blog = {
  "name": "Blog",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "articleByHandle": "Article",
    "articles": "ArticleConnection",
    "authors": "ArticleAuthor",
    "handle": "String",
    "id": "ID",
    "seo": "SEO",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": true
};
Object.freeze(Blog.fieldBaseTypes);
export default Object.freeze(Blog);