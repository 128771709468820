
const CheckoutAttributesUpdateInput = {
  "name": "CheckoutAttributesUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "note": "String",
    "customAttributes": "AttributeInput",
    "allowPartialAddresses": "Boolean"
  }
};
Object.freeze(CheckoutAttributesUpdateInput.inputFieldBaseTypes);
export default Object.freeze(CheckoutAttributesUpdateInput);