
const CheckoutLineItemInput = {
  "name": "CheckoutLineItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "customAttributes": "AttributeInput",
    "quantity": "Int",
    "variantId": "ID"
  }
};
Object.freeze(CheckoutLineItemInput.inputFieldBaseTypes);
export default Object.freeze(CheckoutLineItemInput);