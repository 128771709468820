
const Product = {
  "name": "Product",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableForSale": "Boolean",
    "collections": "CollectionConnection",
    "compareAtPriceRange": "ProductPriceRange",
    "createdAt": "DateTime",
    "description": "String",
    "descriptionHtml": "HTML",
    "handle": "String",
    "id": "ID",
    "images": "ImageConnection",
    "media": "MediaConnection",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "onlineStoreUrl": "URL",
    "options": "ProductOption",
    "presentmentPriceRanges": "ProductPriceRangeConnection",
    "priceRange": "ProductPriceRange",
    "productType": "String",
    "publishedAt": "DateTime",
    "seo": "SEO",
    "tags": "String",
    "title": "String",
    "totalInventory": "Int",
    "updatedAt": "DateTime",
    "variantBySelectedOptions": "ProductVariant",
    "variants": "ProductVariantConnection",
    "vendor": "String"
  },
  "implementsNode": true
};
Object.freeze(Product.fieldBaseTypes);
export default Object.freeze(Product);