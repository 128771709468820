
const CheckoutLineItemsAddPayload = {
  "name": "CheckoutLineItemsAddPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "checkoutUserErrors": "CheckoutUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutLineItemsAddPayload.fieldBaseTypes);
export default Object.freeze(CheckoutLineItemsAddPayload);