//TODO refactor to single API class to remove subsequent calls to clients and a single
//TODO fix gql tooling

//get service
import Client from "graphql-js-client";
import { gql } from "babel-plugin-graphql-js-client-transform";
import typeBundle from "../schema/types";

export const ENDPOINT_URL = process.env.ENDPOINT_URL;
const shopifyAPIVersion = "2022-10";

export const client = (storeUrl, storefrontAccessToken) => {
  return new Client(typeBundle, {
    url: `https://${storeUrl}/api/${shopifyAPIVersion}/graphql.json`,
    fetcherOptions: {
      headers: {
        "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
      },
    },
  });
};

const calculateTotalItems = (lineItems) => {
  return lineItems.reduce((acc, item) => item.quantity + acc, 0);
};

//TODO remove and replace with a single API class object global
const clients = {};

const getClient = (accessToken, shop) => {
  if (!clients[shop]) {
    clients[shop] = client(shop, accessToken);
  }
  return clients[shop];
};

/***
 * Fetches microsite from IS backend
 *
 * @param{string} slug - The slug pointing to the microsite
 * @returns{Promise<object>}
 */
export const fetchMicrosite = async (slug) => {
  const resp = await fetch(`${ENDPOINT_URL}/shopping/${slug}`, {
    method: "GET",
    mode: "cors",
  });
  if (resp.status === 200) {
    return resp.json();
  }
  console.error(resp);
  return null;
};

/***
 * Fetches discount code
 *
 * @param{string} slug - The slug pointing to the microsite
 * @param{Array.<string>} variantIds - The variant IDs being checked out
 * @param{string} token - The recaptcha token
 * @returns{Promise<string>} Discount code
 */
export const fetchDiscountCode = async (slug, variantIds, token) => {
  const resp = await fetch(`${ENDPOINT_URL}/shopping/${slug}/checkout`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      variants: variantIds,
      token,
    }),
  });
  if (resp.status !== 200) {
    throw resp;
  }
  const { code } = await resp.json();
  return code;
};

/***
 * Creates a checkout in storefront
 *
 * @param{string} accessToken - The storefront access token
 * @param{string} shop - The shop domain name eg. influence.myshopify.com
 * @returns{Promise<object>} - Checkout object
 */
export const createCheckout = async (accessToken, shop) => {
  const cli = getClient(accessToken, shop);
  const res = await cli.send(
    gql(cli)`mutation {
    checkoutCreate(input: {}) {
      userErrors {
        message
        field
      }
      checkout {
        id
        webUrl
        subtotalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
  `
  );
  return res.model.checkoutCreate.checkout;
};

/***
 * Adds a set of line items to a checkout
 *
 * @param{string} accessToken - The storefront access token
 * @param{string} shop - The shop domain name eg. influence.myshopify.com
 * @param{string} checkoutId - The checkout shopify ID
 * @param{Array.<object>} - The line items to add
 * @returns{Promise<object>} - Checkout object and total items in cart
 */
export const addLineItems = async (
  accessToken,
  shop,
  checkoutId,
  lineItems
) => {
  const cli = getClient(accessToken, shop);
  const res = await cli.send(
    gql(cli)`
  mutation AddLineItems(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        webUrl
        subtotalPrice { 
          amount
          currencyCode
         }
        totalTax { 
          amount
          currencyCode
        }
        totalPrice { 
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price { 
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
  `,
    { checkoutId, lineItems }
  );
  return {
    checkout: res.model.checkoutLineItemsAdd.checkout,
    totalItems: calculateTotalItems(
      res.model.checkoutLineItemsAdd.checkout.lineItems
    ),
  };
};

/***
 * Updates a line item quantity
 *
 * @param{string} accessToken - The storefront access token
 * @param{string} shop - The shop domain name eg. influence.myshopify.com
 * @param{string} checkoutId - The checkout shopify ID
 * @param{Array.<object>} - The line items to add { id{string}, quantity{int}}
 * @returns{Promise<object>} - Checkout object and total items in cart
 */
export const updateLineItems = async (
  accessToken,
  shop,
  checkoutId,
  lineItemQuantities
) => {
  const cli = getClient(accessToken, shop);
  const res = await cli.send(
    gql(cli)`
  mutation UpdateLineItems(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        webUrl
        subtotalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
  `,
    {
      checkoutId,
      lineItems: lineItemQuantities,
    }
  );
  return {
    checkout: res.model.checkoutLineItemsUpdate.checkout,
    totalItems: calculateTotalItems(
      res.model.checkoutLineItemsUpdate.checkout.lineItems
    ),
  };
};

/***
 * Applies a discount code to a cart.
 *
 * @param{string} accessToken - The storefront access token
 * @param{string} shop - The shop domain name eg. influence.myshopify.com
 * @param{string} checkoutId - The checkout shopify ID
 * @param{string} - discountCode - The discount code to apply
 * @returns{Promise<object>} - Checkout object and total items in cart
 */
export const applyDiscountCode = async (
  accessToken,
  shop,
  checkoutId,
  discountCode
) => {
  const cli = getClient(accessToken, shop);
  const res = await cli.send(
    gql(cli)`
  mutation checkoutDiscountCodeApplyV2(
    $discountCode: String!
    $checkoutId: ID!
  ) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
    `,
    { discountCode, checkoutId }
  );
  return res;
};

/***
 * Removes a set of line items from a checkout
 *
 * @param{string} accessToken - The storefront access token
 * @param{string} shop - The shop domain name eg. influence.myshopify.com
 * @param{string} checkoutId - The checkout shopify ID
 * @param{Array.<string>} - The line items to remove
 * @returns{Promise<object>} - Checkout object and total items in cart
 */
export const removeLineItems = async (
  accessToken,
  shop,
  checkoutId,
  lineItemIds
) => {
  const cli = getClient(accessToken, shop);
  const res = await cli.send(
    gql(cli)`
  mutation RemoveLineItems($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkout {
        webUrl
        subtotalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
  `,
    {
      checkoutId,
      lineItemIds,
    }
  );
  return {
    checkout: res.model.checkoutLineItemsRemove.checkout,
    totalItems: calculateTotalItems(
      res.model.checkoutLineItemsRemove.checkout.lineItems
    ),
  };
};

/* Shopify Graphql*/
const createCheckoutMutation = `
  mutation {
    checkoutCreate(input: {}) {
      userErrors {
        message
        field
      }
      checkout {
        id
        webUrl
        subtotalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
`;

const addLineItemsMutation = `
  mutation AddLineItems(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        webUrl
        subtotalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
`;

const updateLineItemsMutation = `
  mutation UpdateLineItems(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        webUrl
        subtotalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
`;

const removeLineItemMutation = `
  mutation RemoveLineItems($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkout {
        webUrl
        subtotalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        lineItems(first: 250) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              title
              variant {
                title
                image {
                  src
                }
                price {
                  amount
                  currencyCode
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
`;

const applyDiscountCodeMutation = `
  mutation checkoutDiscountCodeApplyV2(
    $discountCode: String!
    $checkoutId: ID!
  ) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
