
const CheckoutUserError = {
  "name": "CheckoutUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "CheckoutErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
Object.freeze(CheckoutUserError.fieldBaseTypes);
export default Object.freeze(CheckoutUserError);