const AppliedGiftCard = {
  name: "AppliedGiftCard",
  kind: "OBJECT",
  fieldBaseTypes: {
    amountUsed: "MoneyV2",
    balance: "MoneyV2",
    id: "ID",
    lastCharacters: "String",
    presentmentAmountUsed: "MoneyV2",
  },
  implementsNode: true,
};
Object.freeze(AppliedGiftCard.fieldBaseTypes);
export default Object.freeze(AppliedGiftCard);
