
const BlogEdge = {
  "name": "BlogEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Blog"
  },
  "implementsNode": false
};
Object.freeze(BlogEdge.fieldBaseTypes);
export default Object.freeze(BlogEdge);