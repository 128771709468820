
const StringEdge = {
  "name": "StringEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "String"
  },
  "implementsNode": false
};
Object.freeze(StringEdge.fieldBaseTypes);
export default Object.freeze(StringEdge);