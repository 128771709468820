
const DisplayableError = {
  "name": "DisplayableError",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "field": "String",
    "message": "String"
  },
  "possibleTypes": ["CheckoutUserError", "CustomerUserError", "UserError"]
};
Object.freeze(DisplayableError.fieldBaseTypes);
Object.freeze(DisplayableError.possibleTypes);
export default Object.freeze(DisplayableError);