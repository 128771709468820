
const CustomerUserError = {
  "name": "CustomerUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "CustomerErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
Object.freeze(CustomerUserError.fieldBaseTypes);
export default Object.freeze(CustomerUserError);