
const AttributeInput = {
  "name": "AttributeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "key": "String",
    "value": "String"
  }
};
Object.freeze(AttributeInput.inputFieldBaseTypes);
export default Object.freeze(AttributeInput);