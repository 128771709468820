
const CustomerDefaultAddressUpdatePayload = {
  "name": "CustomerDefaultAddressUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "customerUserErrors": "CustomerUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerDefaultAddressUpdatePayload.fieldBaseTypes);
export default Object.freeze(CustomerDefaultAddressUpdatePayload);