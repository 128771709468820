
const MoneyInput = {
  "name": "MoneyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "amount": "Decimal",
    "currencyCode": "CurrencyCode"
  }
};
Object.freeze(MoneyInput.inputFieldBaseTypes);
export default Object.freeze(MoneyInput);