
const CheckoutCustomerDisassociatePayload = {
  "name": "CheckoutCustomerDisassociatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "checkoutUserErrors": "CheckoutUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCustomerDisassociatePayload.fieldBaseTypes);
export default Object.freeze(CheckoutCustomerDisassociatePayload);