
const CheckoutLineItemEdge = {
  "name": "CheckoutLineItemEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CheckoutLineItem"
  },
  "implementsNode": false
};
Object.freeze(CheckoutLineItemEdge.fieldBaseTypes);
export default Object.freeze(CheckoutLineItemEdge);