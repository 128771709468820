
const PricingPercentageValue = {
  "name": "PricingPercentageValue",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "percentage": "Float"
  },
  "implementsNode": false
};
Object.freeze(PricingPercentageValue.fieldBaseTypes);
export default Object.freeze(PricingPercentageValue);