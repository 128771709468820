
const CustomerCreateInput = {
  "name": "CustomerCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "firstName": "String",
    "lastName": "String",
    "email": "String",
    "phone": "String",
    "password": "String",
    "acceptsMarketing": "Boolean"
  }
};
Object.freeze(CustomerCreateInput.inputFieldBaseTypes);
export default Object.freeze(CustomerCreateInput);