const Checkout = {
  name: "Checkout",
  kind: "OBJECT",
  fieldBaseTypes: {
    appliedGiftCards: "AppliedGiftCard",
    availableShippingRates: "AvailableShippingRates",
    completedAt: "DateTime",
    createdAt: "DateTime",
    currencyCode: "CurrencyCode",
    customAttributes: "Attribute",
    customer: "Customer",
    discountApplications: "DiscountApplicationConnection",
    email: "String",
    id: "ID",
    lineItems: "CheckoutLineItemConnection",
    lineItemsSubtotalPrice: "MoneyV2",
    note: "String",
    order: "Order",
    orderStatusUrl: "URL",
    paymentDue: "MoneyV2",
    ready: "Boolean",
    requiresShipping: "Boolean",
    shippingAddress: "MailingAddress",
    shippingDiscountAllocations: "DiscountAllocation",
    shippingLine: "ShippingRate",
    subtotalPrice: "MoneyV2",
    taxExempt: "Boolean",
    taxesIncluded: "Boolean",
    totalPrice: "MoneyV2",
    totalTax: "MoneyV2",
    updatedAt: "DateTime",
    webUrl: "URL",
  },
  implementsNode: true,
};
Object.freeze(Checkout.fieldBaseTypes);
export default Object.freeze(Checkout);
