
const CustomerActivateInput = {
  "name": "CustomerActivateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "activationToken": "String",
    "password": "String"
  }
};
Object.freeze(CustomerActivateInput.inputFieldBaseTypes);
export default Object.freeze(CustomerActivateInput);