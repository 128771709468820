
const CheckoutCompleteWithTokenizedPaymentV3Payload = {
  "name": "CheckoutCompleteWithTokenizedPaymentV3Payload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "checkoutUserErrors": "CheckoutUserError",
    "payment": "Payment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCompleteWithTokenizedPaymentV3Payload.fieldBaseTypes);
export default Object.freeze(CheckoutCompleteWithTokenizedPaymentV3Payload);