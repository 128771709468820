
import ApiVersion from "./types/api-version";
import AppliedGiftCard from "./types/applied-gift-card";
import Article from "./types/article";
import ArticleAuthor from "./types/article-author";
import ArticleConnection from "./types/article-connection";
import ArticleEdge from "./types/article-edge";
import ArticleSortKeys from "./types/article-sort-keys";
import Attribute from "./types/attribute";
import AttributeInput from "./types/attribute-input";
import AutomaticDiscountApplication from "./types/automatic-discount-application";
import AvailableShippingRates from "./types/available-shipping-rates";
import Blog from "./types/blog";
import BlogConnection from "./types/blog-connection";
import BlogEdge from "./types/blog-edge";
import BlogSortKeys from "./types/blog-sort-keys";
import Boolean from "./types/boolean";
import CardBrand from "./types/card-brand";
import Checkout from "./types/checkout";
import CheckoutAttributesUpdateInput from "./types/checkout-attributes-update-input";
import CheckoutAttributesUpdatePayload from "./types/checkout-attributes-update-payload";
import CheckoutAttributesUpdateV2Input from "./types/checkout-attributes-update-v-2-input";
import CheckoutAttributesUpdateV2Payload from "./types/checkout-attributes-update-v-2-payload";
import CheckoutCompleteFreePayload from "./types/checkout-complete-free-payload";
import CheckoutCompleteWithCreditCardPayload from "./types/checkout-complete-with-credit-card-payload";
import CheckoutCompleteWithCreditCardV2Payload from "./types/checkout-complete-with-credit-card-v-2-payload";
import CheckoutCompleteWithTokenizedPaymentPayload from "./types/checkout-complete-with-tokenized-payment-payload";
import CheckoutCompleteWithTokenizedPaymentV2Payload from "./types/checkout-complete-with-tokenized-payment-v-2-payload";
import CheckoutCompleteWithTokenizedPaymentV3Payload from "./types/checkout-complete-with-tokenized-payment-v-3-payload";
import CheckoutCreateInput from "./types/checkout-create-input";
import CheckoutCreatePayload from "./types/checkout-create-payload";
import CheckoutCustomerAssociatePayload from "./types/checkout-customer-associate-payload";
import CheckoutCustomerAssociateV2Payload from "./types/checkout-customer-associate-v-2-payload";
import CheckoutCustomerDisassociatePayload from "./types/checkout-customer-disassociate-payload";
import CheckoutCustomerDisassociateV2Payload from "./types/checkout-customer-disassociate-v-2-payload";
import CheckoutDiscountCodeApplyPayload from "./types/checkout-discount-code-apply-payload";
import CheckoutDiscountCodeApplyV2Payload from "./types/checkout-discount-code-apply-v-2-payload";
import CheckoutDiscountCodeRemovePayload from "./types/checkout-discount-code-remove-payload";
import CheckoutEmailUpdatePayload from "./types/checkout-email-update-payload";
import CheckoutEmailUpdateV2Payload from "./types/checkout-email-update-v-2-payload";
import CheckoutErrorCode from "./types/checkout-error-code";
import CheckoutGiftCardApplyPayload from "./types/checkout-gift-card-apply-payload";
import CheckoutGiftCardRemovePayload from "./types/checkout-gift-card-remove-payload";
import CheckoutGiftCardRemoveV2Payload from "./types/checkout-gift-card-remove-v-2-payload";
import CheckoutGiftCardsAppendPayload from "./types/checkout-gift-cards-append-payload";
import CheckoutLineItem from "./types/checkout-line-item";
import CheckoutLineItemConnection from "./types/checkout-line-item-connection";
import CheckoutLineItemEdge from "./types/checkout-line-item-edge";
import CheckoutLineItemInput from "./types/checkout-line-item-input";
import CheckoutLineItemUpdateInput from "./types/checkout-line-item-update-input";
import CheckoutLineItemsAddPayload from "./types/checkout-line-items-add-payload";
import CheckoutLineItemsRemovePayload from "./types/checkout-line-items-remove-payload";
import CheckoutLineItemsReplacePayload from "./types/checkout-line-items-replace-payload";
import CheckoutLineItemsUpdatePayload from "./types/checkout-line-items-update-payload";
import CheckoutShippingAddressUpdatePayload from "./types/checkout-shipping-address-update-payload";
import CheckoutShippingAddressUpdateV2Payload from "./types/checkout-shipping-address-update-v-2-payload";
import CheckoutShippingLineUpdatePayload from "./types/checkout-shipping-line-update-payload";
import CheckoutUserError from "./types/checkout-user-error";
import Collection from "./types/collection";
import CollectionConnection from "./types/collection-connection";
import CollectionEdge from "./types/collection-edge";
import CollectionSortKeys from "./types/collection-sort-keys";
import Comment from "./types/comment";
import CommentAuthor from "./types/comment-author";
import CommentConnection from "./types/comment-connection";
import CommentEdge from "./types/comment-edge";
import CountryCode from "./types/country-code";
import CreditCard from "./types/credit-card";
import CreditCardPaymentInput from "./types/credit-card-payment-input";
import CreditCardPaymentInputV2 from "./types/credit-card-payment-input-v-2";
import CropRegion from "./types/crop-region";
import CurrencyCode from "./types/currency-code";
import Customer from "./types/customer";
import CustomerAccessToken from "./types/customer-access-token";
import CustomerAccessTokenCreateInput from "./types/customer-access-token-create-input";
import CustomerAccessTokenCreatePayload from "./types/customer-access-token-create-payload";
import CustomerAccessTokenCreateWithMultipassPayload from "./types/customer-access-token-create-with-multipass-payload";
import CustomerAccessTokenDeletePayload from "./types/customer-access-token-delete-payload";
import CustomerAccessTokenRenewPayload from "./types/customer-access-token-renew-payload";
import CustomerActivateByUrlPayload from "./types/customer-activate-by-url-payload";
import CustomerActivateInput from "./types/customer-activate-input";
import CustomerActivatePayload from "./types/customer-activate-payload";
import CustomerAddressCreatePayload from "./types/customer-address-create-payload";
import CustomerAddressDeletePayload from "./types/customer-address-delete-payload";
import CustomerAddressUpdatePayload from "./types/customer-address-update-payload";
import CustomerCreateInput from "./types/customer-create-input";
import CustomerCreatePayload from "./types/customer-create-payload";
import CustomerDefaultAddressUpdatePayload from "./types/customer-default-address-update-payload";
import CustomerErrorCode from "./types/customer-error-code";
import CustomerRecoverPayload from "./types/customer-recover-payload";
import CustomerResetByUrlPayload from "./types/customer-reset-by-url-payload";
import CustomerResetInput from "./types/customer-reset-input";
import CustomerResetPayload from "./types/customer-reset-payload";
import CustomerUpdateInput from "./types/customer-update-input";
import CustomerUpdatePayload from "./types/customer-update-payload";
import CustomerUserError from "./types/customer-user-error";
import DateTime from "./types/date-time";
import Decimal from "./types/decimal";
import DigitalWallet from "./types/digital-wallet";
import DiscountAllocation from "./types/discount-allocation";
import DiscountApplication from "./types/discount-application";
import DiscountApplicationAllocationMethod from "./types/discount-application-allocation-method";
import DiscountApplicationConnection from "./types/discount-application-connection";
import DiscountApplicationEdge from "./types/discount-application-edge";
import DiscountApplicationTargetSelection from "./types/discount-application-target-selection";
import DiscountApplicationTargetType from "./types/discount-application-target-type";
import DiscountCodeApplication from "./types/discount-code-application";
import DisplayableError from "./types/displayable-error";
import Domain from "./types/domain";
import ExternalVideo from "./types/external-video";
import Float from "./types/float";
import Fulfillment from "./types/fulfillment";
import FulfillmentLineItem from "./types/fulfillment-line-item";
import FulfillmentLineItemConnection from "./types/fulfillment-line-item-connection";
import FulfillmentLineItemEdge from "./types/fulfillment-line-item-edge";
import FulfillmentTrackingInfo from "./types/fulfillment-tracking-info";
import HTML from "./types/html";
import HasMetafields from "./types/has-metafields";
import ID from "./types/id";
import Image from "./types/image";
import ImageConnection from "./types/image-connection";
import ImageContentType from "./types/image-content-type";
import ImageEdge from "./types/image-edge";
import Int from "./types/int";
import MailingAddress from "./types/mailing-address";
import MailingAddressConnection from "./types/mailing-address-connection";
import MailingAddressEdge from "./types/mailing-address-edge";
import MailingAddressInput from "./types/mailing-address-input";
import ManualDiscountApplication from "./types/manual-discount-application";
import Media from "./types/media";
import MediaConnection from "./types/media-connection";
import MediaContentType from "./types/media-content-type";
import MediaEdge from "./types/media-edge";
import MediaImage from "./types/media-image";
import Metafield from "./types/metafield";
import MetafieldConnection from "./types/metafield-connection";
import MetafieldEdge from "./types/metafield-edge";
import MetafieldParentResource from "./types/metafield-parent-resource";
import MetafieldValueType from "./types/metafield-value-type";
import Model3d from "./types/model-3-d";
import Model3dSource from "./types/model-3-d-source";
import Money from "./types/money";
import MoneyInput from "./types/money-input";
import MoneyV2 from "./types/money-v-2";
import MoneyV2Connection from "./types/money-v-2-connection";
import MoneyV2Edge from "./types/money-v-2-edge";
import Mutation from "./types/mutation";
import Node from "./types/node";
import Order from "./types/order";
import OrderCancelReason from "./types/order-cancel-reason";
import OrderConnection from "./types/order-connection";
import OrderEdge from "./types/order-edge";
import OrderFinancialStatus from "./types/order-financial-status";
import OrderFulfillmentStatus from "./types/order-fulfillment-status";
import OrderLineItem from "./types/order-line-item";
import OrderLineItemConnection from "./types/order-line-item-connection";
import OrderLineItemEdge from "./types/order-line-item-edge";
import OrderSortKeys from "./types/order-sort-keys";
import Page from "./types/page";
import PageConnection from "./types/page-connection";
import PageEdge from "./types/page-edge";
import PageInfo from "./types/page-info";
import PageSortKeys from "./types/page-sort-keys";
import Payment from "./types/payment";
import PaymentSettings from "./types/payment-settings";
import PaymentTokenType from "./types/payment-token-type";
import PricingPercentageValue from "./types/pricing-percentage-value";
import PricingValue from "./types/pricing-value";
import Product from "./types/product";
import ProductCollectionSortKeys from "./types/product-collection-sort-keys";
import ProductConnection from "./types/product-connection";
import ProductEdge from "./types/product-edge";
import ProductImageSortKeys from "./types/product-image-sort-keys";
import ProductMediaSortKeys from "./types/product-media-sort-keys";
import ProductOption from "./types/product-option";
import ProductPriceRange from "./types/product-price-range";
import ProductPriceRangeConnection from "./types/product-price-range-connection";
import ProductPriceRangeEdge from "./types/product-price-range-edge";
import ProductSortKeys from "./types/product-sort-keys";
import ProductVariant from "./types/product-variant";
import ProductVariantConnection from "./types/product-variant-connection";
import ProductVariantEdge from "./types/product-variant-edge";
import ProductVariantPricePair from "./types/product-variant-price-pair";
import ProductVariantPricePairConnection from "./types/product-variant-price-pair-connection";
import ProductVariantPricePairEdge from "./types/product-variant-price-pair-edge";
import ProductVariantSortKeys from "./types/product-variant-sort-keys";
import QueryRoot from "./types/query-root";
import SEO from "./types/seo";
import ScriptDiscountApplication from "./types/script-discount-application";
import SelectedOption from "./types/selected-option";
import SelectedOptionInput from "./types/selected-option-input";
import ShippingRate from "./types/shipping-rate";
import Shop from "./types/shop";
import ShopPolicy from "./types/shop-policy";
import String from "./types/string";
import StringConnection from "./types/string-connection";
import StringEdge from "./types/string-edge";
import TokenizedPaymentInput from "./types/tokenized-payment-input";
import TokenizedPaymentInputV2 from "./types/tokenized-payment-input-v-2";
import TokenizedPaymentInputV3 from "./types/tokenized-payment-input-v-3";
import Transaction from "./types/transaction";
import TransactionKind from "./types/transaction-kind";
import TransactionStatus from "./types/transaction-status";
import URL from "./types/url";
import UnitPriceMeasurement from "./types/unit-price-measurement";
import UnitPriceMeasurementMeasuredType from "./types/unit-price-measurement-measured-type";
import UnitPriceMeasurementMeasuredUnit from "./types/unit-price-measurement-measured-unit";
import UserError from "./types/user-error";
import Video from "./types/video";
import VideoSource from "./types/video-source";
import WeightUnit from "./types/weight-unit";
import __Directive from "./types/directive";
import __DirectiveLocation from "./types/directive-location";
import __EnumValue from "./types/enum-value";
import __Field from "./types/field";
import __InputValue from "./types/input-value";
import __Schema from "./types/schema";
import __Type from "./types/type";
import __TypeKind from "./types/type-kind";
const Types = {
  types: {}
};
Types.types["ApiVersion"] = ApiVersion;
Types.types["AppliedGiftCard"] = AppliedGiftCard;
Types.types["Article"] = Article;
Types.types["ArticleAuthor"] = ArticleAuthor;
Types.types["ArticleConnection"] = ArticleConnection;
Types.types["ArticleEdge"] = ArticleEdge;
Types.types["ArticleSortKeys"] = ArticleSortKeys;
Types.types["Attribute"] = Attribute;
Types.types["AttributeInput"] = AttributeInput;
Types.types["AutomaticDiscountApplication"] = AutomaticDiscountApplication;
Types.types["AvailableShippingRates"] = AvailableShippingRates;
Types.types["Blog"] = Blog;
Types.types["BlogConnection"] = BlogConnection;
Types.types["BlogEdge"] = BlogEdge;
Types.types["BlogSortKeys"] = BlogSortKeys;
Types.types["Boolean"] = Boolean;
Types.types["CardBrand"] = CardBrand;
Types.types["Checkout"] = Checkout;
Types.types["CheckoutAttributesUpdateInput"] = CheckoutAttributesUpdateInput;
Types.types["CheckoutAttributesUpdatePayload"] = CheckoutAttributesUpdatePayload;
Types.types["CheckoutAttributesUpdateV2Input"] = CheckoutAttributesUpdateV2Input;
Types.types["CheckoutAttributesUpdateV2Payload"] = CheckoutAttributesUpdateV2Payload;
Types.types["CheckoutCompleteFreePayload"] = CheckoutCompleteFreePayload;
Types.types["CheckoutCompleteWithCreditCardPayload"] = CheckoutCompleteWithCreditCardPayload;
Types.types["CheckoutCompleteWithCreditCardV2Payload"] = CheckoutCompleteWithCreditCardV2Payload;
Types.types["CheckoutCompleteWithTokenizedPaymentPayload"] = CheckoutCompleteWithTokenizedPaymentPayload;
Types.types["CheckoutCompleteWithTokenizedPaymentV2Payload"] = CheckoutCompleteWithTokenizedPaymentV2Payload;
Types.types["CheckoutCompleteWithTokenizedPaymentV3Payload"] = CheckoutCompleteWithTokenizedPaymentV3Payload;
Types.types["CheckoutCreateInput"] = CheckoutCreateInput;
Types.types["CheckoutCreatePayload"] = CheckoutCreatePayload;
Types.types["CheckoutCustomerAssociatePayload"] = CheckoutCustomerAssociatePayload;
Types.types["CheckoutCustomerAssociateV2Payload"] = CheckoutCustomerAssociateV2Payload;
Types.types["CheckoutCustomerDisassociatePayload"] = CheckoutCustomerDisassociatePayload;
Types.types["CheckoutCustomerDisassociateV2Payload"] = CheckoutCustomerDisassociateV2Payload;
Types.types["CheckoutDiscountCodeApplyPayload"] = CheckoutDiscountCodeApplyPayload;
Types.types["CheckoutDiscountCodeApplyV2Payload"] = CheckoutDiscountCodeApplyV2Payload;
Types.types["CheckoutDiscountCodeRemovePayload"] = CheckoutDiscountCodeRemovePayload;
Types.types["CheckoutEmailUpdatePayload"] = CheckoutEmailUpdatePayload;
Types.types["CheckoutEmailUpdateV2Payload"] = CheckoutEmailUpdateV2Payload;
Types.types["CheckoutErrorCode"] = CheckoutErrorCode;
Types.types["CheckoutGiftCardApplyPayload"] = CheckoutGiftCardApplyPayload;
Types.types["CheckoutGiftCardRemovePayload"] = CheckoutGiftCardRemovePayload;
Types.types["CheckoutGiftCardRemoveV2Payload"] = CheckoutGiftCardRemoveV2Payload;
Types.types["CheckoutGiftCardsAppendPayload"] = CheckoutGiftCardsAppendPayload;
Types.types["CheckoutLineItem"] = CheckoutLineItem;
Types.types["CheckoutLineItemConnection"] = CheckoutLineItemConnection;
Types.types["CheckoutLineItemEdge"] = CheckoutLineItemEdge;
Types.types["CheckoutLineItemInput"] = CheckoutLineItemInput;
Types.types["CheckoutLineItemUpdateInput"] = CheckoutLineItemUpdateInput;
Types.types["CheckoutLineItemsAddPayload"] = CheckoutLineItemsAddPayload;
Types.types["CheckoutLineItemsRemovePayload"] = CheckoutLineItemsRemovePayload;
Types.types["CheckoutLineItemsReplacePayload"] = CheckoutLineItemsReplacePayload;
Types.types["CheckoutLineItemsUpdatePayload"] = CheckoutLineItemsUpdatePayload;
Types.types["CheckoutShippingAddressUpdatePayload"] = CheckoutShippingAddressUpdatePayload;
Types.types["CheckoutShippingAddressUpdateV2Payload"] = CheckoutShippingAddressUpdateV2Payload;
Types.types["CheckoutShippingLineUpdatePayload"] = CheckoutShippingLineUpdatePayload;
Types.types["CheckoutUserError"] = CheckoutUserError;
Types.types["Collection"] = Collection;
Types.types["CollectionConnection"] = CollectionConnection;
Types.types["CollectionEdge"] = CollectionEdge;
Types.types["CollectionSortKeys"] = CollectionSortKeys;
Types.types["Comment"] = Comment;
Types.types["CommentAuthor"] = CommentAuthor;
Types.types["CommentConnection"] = CommentConnection;
Types.types["CommentEdge"] = CommentEdge;
Types.types["CountryCode"] = CountryCode;
Types.types["CreditCard"] = CreditCard;
Types.types["CreditCardPaymentInput"] = CreditCardPaymentInput;
Types.types["CreditCardPaymentInputV2"] = CreditCardPaymentInputV2;
Types.types["CropRegion"] = CropRegion;
Types.types["CurrencyCode"] = CurrencyCode;
Types.types["Customer"] = Customer;
Types.types["CustomerAccessToken"] = CustomerAccessToken;
Types.types["CustomerAccessTokenCreateInput"] = CustomerAccessTokenCreateInput;
Types.types["CustomerAccessTokenCreatePayload"] = CustomerAccessTokenCreatePayload;
Types.types["CustomerAccessTokenCreateWithMultipassPayload"] = CustomerAccessTokenCreateWithMultipassPayload;
Types.types["CustomerAccessTokenDeletePayload"] = CustomerAccessTokenDeletePayload;
Types.types["CustomerAccessTokenRenewPayload"] = CustomerAccessTokenRenewPayload;
Types.types["CustomerActivateByUrlPayload"] = CustomerActivateByUrlPayload;
Types.types["CustomerActivateInput"] = CustomerActivateInput;
Types.types["CustomerActivatePayload"] = CustomerActivatePayload;
Types.types["CustomerAddressCreatePayload"] = CustomerAddressCreatePayload;
Types.types["CustomerAddressDeletePayload"] = CustomerAddressDeletePayload;
Types.types["CustomerAddressUpdatePayload"] = CustomerAddressUpdatePayload;
Types.types["CustomerCreateInput"] = CustomerCreateInput;
Types.types["CustomerCreatePayload"] = CustomerCreatePayload;
Types.types["CustomerDefaultAddressUpdatePayload"] = CustomerDefaultAddressUpdatePayload;
Types.types["CustomerErrorCode"] = CustomerErrorCode;
Types.types["CustomerRecoverPayload"] = CustomerRecoverPayload;
Types.types["CustomerResetByUrlPayload"] = CustomerResetByUrlPayload;
Types.types["CustomerResetInput"] = CustomerResetInput;
Types.types["CustomerResetPayload"] = CustomerResetPayload;
Types.types["CustomerUpdateInput"] = CustomerUpdateInput;
Types.types["CustomerUpdatePayload"] = CustomerUpdatePayload;
Types.types["CustomerUserError"] = CustomerUserError;
Types.types["DateTime"] = DateTime;
Types.types["Decimal"] = Decimal;
Types.types["DigitalWallet"] = DigitalWallet;
Types.types["DiscountAllocation"] = DiscountAllocation;
Types.types["DiscountApplication"] = DiscountApplication;
Types.types["DiscountApplicationAllocationMethod"] = DiscountApplicationAllocationMethod;
Types.types["DiscountApplicationConnection"] = DiscountApplicationConnection;
Types.types["DiscountApplicationEdge"] = DiscountApplicationEdge;
Types.types["DiscountApplicationTargetSelection"] = DiscountApplicationTargetSelection;
Types.types["DiscountApplicationTargetType"] = DiscountApplicationTargetType;
Types.types["DiscountCodeApplication"] = DiscountCodeApplication;
Types.types["DisplayableError"] = DisplayableError;
Types.types["Domain"] = Domain;
Types.types["ExternalVideo"] = ExternalVideo;
Types.types["Float"] = Float;
Types.types["Fulfillment"] = Fulfillment;
Types.types["FulfillmentLineItem"] = FulfillmentLineItem;
Types.types["FulfillmentLineItemConnection"] = FulfillmentLineItemConnection;
Types.types["FulfillmentLineItemEdge"] = FulfillmentLineItemEdge;
Types.types["FulfillmentTrackingInfo"] = FulfillmentTrackingInfo;
Types.types["HTML"] = HTML;
Types.types["HasMetafields"] = HasMetafields;
Types.types["ID"] = ID;
Types.types["Image"] = Image;
Types.types["ImageConnection"] = ImageConnection;
Types.types["ImageContentType"] = ImageContentType;
Types.types["ImageEdge"] = ImageEdge;
Types.types["Int"] = Int;
Types.types["MailingAddress"] = MailingAddress;
Types.types["MailingAddressConnection"] = MailingAddressConnection;
Types.types["MailingAddressEdge"] = MailingAddressEdge;
Types.types["MailingAddressInput"] = MailingAddressInput;
Types.types["ManualDiscountApplication"] = ManualDiscountApplication;
Types.types["Media"] = Media;
Types.types["MediaConnection"] = MediaConnection;
Types.types["MediaContentType"] = MediaContentType;
Types.types["MediaEdge"] = MediaEdge;
Types.types["MediaImage"] = MediaImage;
Types.types["Metafield"] = Metafield;
Types.types["MetafieldConnection"] = MetafieldConnection;
Types.types["MetafieldEdge"] = MetafieldEdge;
Types.types["MetafieldParentResource"] = MetafieldParentResource;
Types.types["MetafieldValueType"] = MetafieldValueType;
Types.types["Model3d"] = Model3d;
Types.types["Model3dSource"] = Model3dSource;
Types.types["Money"] = Money;
Types.types["MoneyInput"] = MoneyInput;
Types.types["MoneyV2"] = MoneyV2;
Types.types["MoneyV2Connection"] = MoneyV2Connection;
Types.types["MoneyV2Edge"] = MoneyV2Edge;
Types.types["Mutation"] = Mutation;
Types.types["Node"] = Node;
Types.types["Order"] = Order;
Types.types["OrderCancelReason"] = OrderCancelReason;
Types.types["OrderConnection"] = OrderConnection;
Types.types["OrderEdge"] = OrderEdge;
Types.types["OrderFinancialStatus"] = OrderFinancialStatus;
Types.types["OrderFulfillmentStatus"] = OrderFulfillmentStatus;
Types.types["OrderLineItem"] = OrderLineItem;
Types.types["OrderLineItemConnection"] = OrderLineItemConnection;
Types.types["OrderLineItemEdge"] = OrderLineItemEdge;
Types.types["OrderSortKeys"] = OrderSortKeys;
Types.types["Page"] = Page;
Types.types["PageConnection"] = PageConnection;
Types.types["PageEdge"] = PageEdge;
Types.types["PageInfo"] = PageInfo;
Types.types["PageSortKeys"] = PageSortKeys;
Types.types["Payment"] = Payment;
Types.types["PaymentSettings"] = PaymentSettings;
Types.types["PaymentTokenType"] = PaymentTokenType;
Types.types["PricingPercentageValue"] = PricingPercentageValue;
Types.types["PricingValue"] = PricingValue;
Types.types["Product"] = Product;
Types.types["ProductCollectionSortKeys"] = ProductCollectionSortKeys;
Types.types["ProductConnection"] = ProductConnection;
Types.types["ProductEdge"] = ProductEdge;
Types.types["ProductImageSortKeys"] = ProductImageSortKeys;
Types.types["ProductMediaSortKeys"] = ProductMediaSortKeys;
Types.types["ProductOption"] = ProductOption;
Types.types["ProductPriceRange"] = ProductPriceRange;
Types.types["ProductPriceRangeConnection"] = ProductPriceRangeConnection;
Types.types["ProductPriceRangeEdge"] = ProductPriceRangeEdge;
Types.types["ProductSortKeys"] = ProductSortKeys;
Types.types["ProductVariant"] = ProductVariant;
Types.types["ProductVariantConnection"] = ProductVariantConnection;
Types.types["ProductVariantEdge"] = ProductVariantEdge;
Types.types["ProductVariantPricePair"] = ProductVariantPricePair;
Types.types["ProductVariantPricePairConnection"] = ProductVariantPricePairConnection;
Types.types["ProductVariantPricePairEdge"] = ProductVariantPricePairEdge;
Types.types["ProductVariantSortKeys"] = ProductVariantSortKeys;
Types.types["QueryRoot"] = QueryRoot;
Types.types["SEO"] = SEO;
Types.types["ScriptDiscountApplication"] = ScriptDiscountApplication;
Types.types["SelectedOption"] = SelectedOption;
Types.types["SelectedOptionInput"] = SelectedOptionInput;
Types.types["ShippingRate"] = ShippingRate;
Types.types["Shop"] = Shop;
Types.types["ShopPolicy"] = ShopPolicy;
Types.types["String"] = String;
Types.types["StringConnection"] = StringConnection;
Types.types["StringEdge"] = StringEdge;
Types.types["TokenizedPaymentInput"] = TokenizedPaymentInput;
Types.types["TokenizedPaymentInputV2"] = TokenizedPaymentInputV2;
Types.types["TokenizedPaymentInputV3"] = TokenizedPaymentInputV3;
Types.types["Transaction"] = Transaction;
Types.types["TransactionKind"] = TransactionKind;
Types.types["TransactionStatus"] = TransactionStatus;
Types.types["URL"] = URL;
Types.types["UnitPriceMeasurement"] = UnitPriceMeasurement;
Types.types["UnitPriceMeasurementMeasuredType"] = UnitPriceMeasurementMeasuredType;
Types.types["UnitPriceMeasurementMeasuredUnit"] = UnitPriceMeasurementMeasuredUnit;
Types.types["UserError"] = UserError;
Types.types["Video"] = Video;
Types.types["VideoSource"] = VideoSource;
Types.types["WeightUnit"] = WeightUnit;
Types.types["__Directive"] = __Directive;
Types.types["__DirectiveLocation"] = __DirectiveLocation;
Types.types["__EnumValue"] = __EnumValue;
Types.types["__Field"] = __Field;
Types.types["__InputValue"] = __InputValue;
Types.types["__Schema"] = __Schema;
Types.types["__Type"] = __Type;
Types.types["__TypeKind"] = __TypeKind;
Types.queryType = "QueryRoot";
Types.mutationType = "Mutation";
Types.subscriptionType = null;
Object.freeze(Types.types);
export default Object.freeze(Types);