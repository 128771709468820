
const FulfillmentLineItemEdge = {
  "name": "FulfillmentLineItemEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "FulfillmentLineItem"
  },
  "implementsNode": false
};
Object.freeze(FulfillmentLineItemEdge.fieldBaseTypes);
export default Object.freeze(FulfillmentLineItemEdge);