
const CommentEdge = {
  "name": "CommentEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Comment"
  },
  "implementsNode": false
};
Object.freeze(CommentEdge.fieldBaseTypes);
export default Object.freeze(CommentEdge);