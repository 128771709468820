
const CustomerActivateByUrlPayload = {
  "name": "CustomerActivateByUrlPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "customerAccessToken": "CustomerAccessToken",
    "customerUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerActivateByUrlPayload.fieldBaseTypes);
export default Object.freeze(CustomerActivateByUrlPayload);