const Order = {
  name: "Order",
  kind: "OBJECT",
  fieldBaseTypes: {
    cancelReason: "OrderCancelReason",
    canceledAt: "DateTime",
    currencyCode: "CurrencyCode",
    currentSubtotalPrice: "MoneyV2",
    currentTotalPrice: "MoneyV2",
    currentTotalTax: "MoneyV2",
    customerLocale: "String",
    customerUrl: "URL",
    discountApplications: "DiscountApplicationConnection",
    edited: "Boolean",
    email: "String",
    financialStatus: "OrderFinancialStatus",
    fulfillmentStatus: "OrderFulfillmentStatus",
    id: "ID",
    lineItems: "OrderLineItemConnection",
    name: "String",
    orderNumber: "Int",
    originalTotalPrice: "MoneyV2",
    phone: "String",
    processedAt: "DateTime",
    shippingAddress: "MailingAddress",
    shippingDiscountAllocations: "DiscountAllocation",
    statusUrl: "URL",
    subtotalPrice: "MoneyV2",
    successfulFulfillments: "Fulfillment",
    totalPrice: "MoneyV2",
    totalRefunded: "MoneyV2",
    totalShippingPrice: "MoneyV2",
    totalTax: "MoneyV2",
  },
  implementsNode: true,
};
Object.freeze(Order.fieldBaseTypes);
export default Object.freeze(Order);
