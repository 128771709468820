
const AvailableShippingRates = {
  "name": "AvailableShippingRates",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "ready": "Boolean",
    "shippingRates": "ShippingRate"
  },
  "implementsNode": false
};
Object.freeze(AvailableShippingRates.fieldBaseTypes);
export default Object.freeze(AvailableShippingRates);