
const Fulfillment = {
  "name": "Fulfillment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentLineItems": "FulfillmentLineItemConnection",
    "trackingCompany": "String",
    "trackingInfo": "FulfillmentTrackingInfo"
  },
  "implementsNode": false
};
Object.freeze(Fulfillment.fieldBaseTypes);
export default Object.freeze(Fulfillment);