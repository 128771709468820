
const SelectedOptionInput = {
  "name": "SelectedOptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "value": "String"
  }
};
Object.freeze(SelectedOptionInput.inputFieldBaseTypes);
export default Object.freeze(SelectedOptionInput);