
const DiscountApplicationEdge = {
  "name": "DiscountApplicationEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "DiscountApplication"
  },
  "implementsNode": false
};
Object.freeze(DiscountApplicationEdge.fieldBaseTypes);
export default Object.freeze(DiscountApplicationEdge);